.submitBtn {
  width: 100%;
  height: 2.938rem;
  font-weight: 500;
  font-size: 0.875rem;
  border-radius: 0.3125rem;
}

.ready,
.wrong {
  background-color: var(--blue-1);
  color: var(--blue-3);
  pointer-events: none;
}

.right {
  background-color: var(--blue-4);
  color: var(--white);
}
