.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.5rem;
  overflow-y: auto;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.5rem;
  /* 양옆 여백 */
  padding: 4rem 1rem 12rem 1rem;
  row-gap: 0.5rem;
}

.empty {
  height: 100vh;
}

.writeButton {
  bottom: 1.1875rem;
}
