.deleteAccountPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.titleDescWrapper {
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  gap: 0.875rem;
  flex-grow: 1;
}

.title {
  color: var(--black);
  font-size: 1.25rem;
  font-weight: 700;
}

.descWrapper {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0.3125rem;
  padding-bottom: 0.8125rem;
}

.desc {
  color: var(--gray-4);
  font-size: 0.875rem;
  font-weight: 500;
}

/* 하단 버튼 */
.buttonWrapper {
  display: flex;
  flex-direction: column;
  bottom: 0;
  width: 100%;
  gap: 0.5rem;
  padding: 1.25rem 1.25rem 3.375rem;
}

.goBackButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2.9375rem;
  border-radius: 0.3125rem;
  background: var(--blue-1);
  color: var(--blue-3);
  font-size: 0.875rem;
  font-weight: 500;
}

.deleteAccountButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2.9375rem;
  border-radius: 0.3125rem;
  background: var(--blue-4);
  color: var(--white);
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
}
