.title {
  font-size: 1.1rem;
  font-weight: 500;
}

.content {
  margin: 0.5rem 0 2rem 0;
  white-space: pre-wrap;
  word-break: keep-all;
}
