.container {
  padding-bottom: 7rem;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.notification {
  width: 100%;
  padding: 0.625rem var(--default-margin);
  padding-top: 4rem;
}

.notificationBar {
  width: 100%;
  padding: 0.688rem 0.938rem;
  display: flex;
  align-items: center;
  gap: 0.861rem;
  border-radius: 0.3125rem;
  background-color: var(--pink-1);
  font-size: 0.688rem;
  color: var(--pink-3);
  box-sizing: border-box;
  cursor: pointer;
}

.search {
  width: calc(100% - 1.25rem * 2);
  margin: 0 1.25rem 0.625rem 1.25rem;
}
