.content {
  font-size: 0.75rem;
  color: var(--gray-4);
  font-size: 0.875rem;
  padding: 0 0.8125rem 0.9375rem 0;
  white-space: pre-wrap;
  word-break: keep-all;
}

.content a {
  color: var(--blue-3);
}

.content a:hover {
  text-decoration: underline;
}
