.tile {
  width: 2rem;
  height: 3.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.day {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  text-align: center;
  color: var(--white);
}

.day.today {
  border-radius: 100%;
  background: var(--blue-1);
  font-weight: 700;
  color: var(--blue-4);
}

.difference {
  font-size: 0.75rem;
  text-align: center;
  color: var(--pink-2);
}
