.editInfoPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 6.25rem;
}

.topContainer {
  display: flex;
  justify-content: space-between;
}

.submitBtn {
  background-color: transparent;
  padding: 0.875rem 1.25rem;
  font-size: 1rem;
}

/* 프로필 */
.profileContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1.25rem;
}

.profileImgContainer {
  display: flex;
  justify-content: center;
}

.profileImg {
  --size: 6.5rem;

  width: var(--size);
  height: var(--size);
  padding: 0.75rem;
  display: flex;
  flex-shrink: 0;
  position: relative;
  /* cursor: pointer; */
}

.profileImg img {
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 50%;
}

.blueCamera {
  position: absolute;
  bottom: 0.9375rem;
  right: 0.9375rem;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.infoWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 0.375rem;
}

.title {
  color: var(--gray-4);
  font-size: 0.875rem;
  font-weight: 500;
}

.inputText {
  display: flex;
  width: 100%;
  height: 2.5rem;
  border-radius: 0.3125rem;
  padding: 0 0.75rem;
  border: none;
  background: var(--gray-1-1);
  outline: none;
  color: var(--black);
  font-size: 0.75rem;

  &:disabled {
    background: var(--gray-3);
  }
}

.errorMessage {
  position: absolute;
  color: var(--pink-3);
  font-size: 0.6875rem;
  top: 4.4375rem;
}

.errorInputText {
  background: var(--pink-1);
  border-radius: 0.3125rem;
}
