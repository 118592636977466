.title {
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 0.349rem;
}

.input {
  width: 100%;
  height: 2.563rem;
  border-radius: 0.375rem;
  padding-left: 1rem;
  font-size: 0.75rem;
  outline: 0;
  border: 0;
}

.pwFrame {
  width: 100%;
  display: flex;
  align-items: center;
  height: 2.563rem;
  border-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  padding-right: 0.625rem;
}

.ready {
  background-color: var(--gray-1-1);
}

.right {
  background-color: var(--blue-0);
}

.wrong {
  background-color: var(--pink-1);
}

.pwInput {
  width: 80%;
  background-color: var(--blue-4);
  display: inline-block;
}

.icon {
  display: inline-block;
}

.icon:hover {
  cursor: pointer;
}

.error {
  color: var(--pink-3);
  font-size: 0.688rem;
  margin-top: 0.188rem;
}
