.textarea {
  height: 8.25rem;
  padding: 0.9rem 1rem;
  grid-column: span 2;
  background: var(--gray-2);
  border-radius: 0.3125rem;
  border: none;
  outline: none;
  resize: none;
  font-size: 0.8125rem;
}

.textarea::placeholder {
  color: var(--gray-3-1);
}
