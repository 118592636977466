.header {
  margin: 0 var(--default-margin) 0.5rem var(--default-margin);
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: 700;
  font-size: 1rem;
}

.more {
  display: flex;
  align-items: center;
  gap: 1px;
  font-weight: 500;
  font-size: 0.75rem;
  color: var(--gray-4);
}
