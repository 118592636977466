.appBar {
  padding: 0.875rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 600px;
  background-color: var(--white);
  position: fixed;
  top: 0;
  z-index: 1;
}

.close {
  cursor: pointer;
}

.actions {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
}
