.list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.item {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
}

.icon {
  margin-top: 2px;
}

.description {
  flex: 1;
  word-break: keep-all;
}
