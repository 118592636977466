.loading {
  max-width: var(--default-width);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(var(--black-rgb), 0.4);
  z-index: 1;
}

.loading .text {
  font-size: 1rem;
  color: var(--white);
}
