a:hover {
  cursor: pointer;
}

.prev:hover {
  cursor: pointer;
}

.loginframe {
  background-color: var(--white);
  width: 100%;
  height: 100vh;
  margin: 0 auto;
}

.back {
  margin-top: 0.875rem;
}
.back:hover {
  cursor: pointer;
}

.loginBody {
  padding: 0 1.25rem;
  font-size: 0.5625rem;
  text-align: center;
  font-family: 'Noto Sans KR', sans-serif;
}

.logo {
  margin-left: auto;
  margin-right: auto;
  margin-top: 6.417rem;
  margin-bottom: 1.538rem;
}

.title {
  font-size: 0.875rem;
  font-style: normal;
  margin-bottom: 3.577rem;
}

.input {
  margin-bottom: 1.063rem;
}

.pwFrame {
  width: 100%;
  display: flex;
  align-items: center;
  height: 2.563rem;
  border-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  padding-right: 0.625rem;
  margin-bottom: 1rem;
}

.ready {
  background-color: var(--gray-1-1);
}

.wrong {
  background-color: var(--pink-1);
}

.pwInput {
  width: 80%;
  background-color: var(--blue-4);
  display: inline-block;
}

.visibility {
  display: inline-block;
}

.visibility:hover {
  cursor: pointer;
}

.button {
  display: block;
  width: 11.25rem;
  height: 2rem;
  background-color: var(--blue-4);
  color: var(--white);
  margin: 1.125rem auto;
  border-radius: 0.4375rem;
}

.find {
  display: flex;
  justify-content: center;
  color: var(--gray-3-1);
  font-size: 0.75rem;
  margin-top: 2.313rem;
  margin-bottom: 0.75rem;
}

.divider {
  margin: 0 0.688rem;
}

.signUp {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 0.875rem;
}
