.container {
  width: 100%;
  padding: 0.6875rem 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: var(--gray-1-1);
  border-radius: 0.3125rem;
}

.search {
  flex: 1;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 0.75rem;
  color: var(--black);
}

.search::placeholder {
  font-size: 0.75rem;
  color: var(--gray-3-1);
}
