.header {
  margin-bottom: 1.25rem;
}

.carousel {
  margin-bottom: 2.5625rem;
}

.community {
  margin-bottom: 25px;
}
