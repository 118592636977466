.appBar {
  padding: 0.875rem 1.25rem;
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  position: fixed;
  top: 0;
  z-index: 1;
}

.title {
  font-weight: 700;
  font-size: 1.25rem;
}
