.comment {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.3125rem;
  background: var(--gray-1);
}

.commentTop {
  width: 100%;
  padding: 0.375rem 0 0.25rem 0.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--gray-3-1);
  font-size: 0.6875rem;
}

.commentTopLeft {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.cloud {
  display: flex;
  height: 0.75rem;
  align-items: center;
}

.isWriterWithdrawn {
  color: var(--gray-3-1);
}

.dot {
  width: 0.1875rem;
  height: 0.1875rem;
  background: var(--gray-3-1);
  border-radius: 100%;
}

.dot3 {
  padding: 1.25rem 0.875rem 0.125rem 0.625rem;
  cursor: pointer;
}

.commentCenter {
  color: var(--gray-4);
  font-size: 0.875rem;
  padding: 0 0.875rem;
  white-space: pre-wrap;
}

.commentCenter.hide {
  color: var(--gray-3-1);
}

.content a {
  color: var(--blue-3);
}

.content a:hover {
  text-decoration: underline;
}

.commentBottom {
  min-height: 2.5rem;
  margin-right: 0.5rem;
  display: flex;
  justify-content: end;
  align-items: center;
}

.commentCount {
  padding: 0.25rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  border-radius: 0.3125rem;
  color: var(--gray-3-1);
  background-color: transparent;
  font-size: 0.6875rem;
}

.commentCount:hover {
  background-color: var(--gray-1-1);
}

.likedCount {
  padding: 0.25rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;
  border-radius: 0.3125rem;
  color: var(--gray-3-1);
  background-color: transparent;
  font-size: 0.6875rem;
}

.likedCount:hover {
  background-color: var(--gray-1-1);
}

.nestedComment {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--gray-2);
}

.nestedComment.isLast {
  padding-bottom: 0.375rem;
  border-radius: 0 0 0.3125rem 0.3125rem;
}

.nestedCommentTop {
  width: 100%;
  padding: 0.625rem 0 0 0.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--gray-3-1);
  font-size: 0.6875rem;
}

.nestedIcon {
  display: flex;
  width: 1rem;
  padding-bottom: 0.625rem;
}

.nestedPadding {
  width: 100%;
  padding-left: 1.375rem;
}

.nestedPadding.hide {
  color: var(--gray-3-1);
}
