.comments {
  width: 100%;
  font-size: 0.75rem;
  padding: 0 1.25rem 6.25rem 1.25rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.4375rem;
  min-height: 300px;
}

.commentsTitle {
  padding: 0.875rem 0 0.125rem 0;
}

.failComment {
  height: 18.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
