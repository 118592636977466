.appBar {
  padding: 0.875rem 1.25rem;
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  position: fixed;
  top: 0;
  z-index: 1;
}

.hasGap {
  /* padding: 0.875rem 1.25rem;
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: space-between; */
  gap: 0.75rem;
}

.backDiv {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.back {
  cursor: pointer;
}

.title {
  font-weight: 700;
  font-size: 1.25rem;
}

.actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.hasWideWidth {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}
