.app {
  width: 100vw;
  height: 100%;
  max-width: var(--default-width);
  margin: 0 auto;
  position: relative;
  background-color: var(--white);
  overflow-x: hidden;
  overflow-y: auto;
}
