.post {
  padding: 0.8125rem 0.9375rem;
  width: 100%;
  background-color: var(--gray-1);
  border-radius: 0.3125rem;
  cursor: pointer;
  user-select: none;
}

.post_top {
  padding-bottom: 0.5625rem;
  display: flex;
  align-items: center;
  font-size: 0.6875rem;
  color: var(--gray-4);
  white-space: nowrap;
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 9.75rem;
  padding-left: 0.375rem;
}

.dot {
  font-weight: 800;
  padding: 0 0.1875rem;
}

.checkCircleIcon {
  margin-left: 0.25rem;
}

.post_center {
  display: flex;
  flex-direction: column;
  row-gap: 0.375rem;
}

.title {
  font-size: 0.875rem;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text {
  font-size: 0.8125rem;
  color: var(--gray-4);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.625rem;
  padding: 0.3125rem 0 0 0;
  color: var(--gray-3-1);
  justify-content: space-between;

  p {
    margin-bottom: 0.1875rem;
  }
}

.board {
  display: flex;
  align-items: center;
  margin-bottom: 0.1875rem;
}

.iconContainer {
  display: flex;
  align-items: center;
  gap: 0.4375rem;
  font-size: 0.6875rem;
  color: var(--gray-3-1);
}

.comment {
  margin-top: 1px;
}
