.header {
  width: 100%;
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.button {
  background-color: var(--white);
  font-weight: bold;
  font-weight: 500;
  font-size: 0.8125rem;
  color: var(--blue-4);
}
