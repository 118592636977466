.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error {
  font-size: 5rem;
  color: var(--blue-4);
  font-weight: 700;
}

.text {
  margin-bottom: 1.25rem;
}

.button {
  width: 20%;
}
