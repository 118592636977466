.post {
  padding: 0.8125rem 0.9375rem;
  width: 100%;
  background-color: var(--gray-1);
  border-radius: 0.3125rem;
  cursor: pointer;
}

.post_top {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 0.875rem;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post_center {
  padding-top: 0.375rem;
}

.text {
  font-size: 0.8125rem;
  color: #484848;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.postBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  color: var(--gray-3-1);
  font-size: 0.6875rem;
}

.postBottomRight {
  gap: 0.375rem;
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0.1875rem;
  }
}
