.pageFrame {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.scrollFrame {
  overflow-y: scroll;
}

.text {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1.875rem;
}

.inputFrame {
  margin-bottom: 1.5rem;
}

.majorTitle {
  font-size: 0.875rem;
  margin-bottom: 0.375rem;
}

.submit {
  margin-bottom: 2.125rem;
}
