.policyPage {
  display: flex;
  flex-direction: column;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 20px 54px 20px;
  width: 100%;
  gap: 14px;
  padding-top: 50px;
}
.contentWrapper section {
  margin-bottom: 20px;
}
.contentWrapper h2 {
  padding: 3px 2px;
  font-size: 16px;
  font-weight: bold;
}
.contentWrapper p {
  padding: 4px 2px;
}
.contentWrapper ul {
  list-style-type: disc;
  padding-left: 20px;
}
.contentWrapper ol {
  list-style-type: decimal;
  padding-left: 22px;
}

.title {
  font-size: 20px;
  font-weight: 700;
}

.content {
  padding: 16px 13px;
  border-radius: 5px;
  background: var(--Miscellaneous-_Kit-Section-Fill, #f5f5f5);
  color: var(--Grey-4, var(--grey-3, #484848));
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.5px;
  white-space: pre-line;
}
