.field {
  padding: 0 1.25rem;
  margin: 1.75rem 0;
}

.header {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--gray-4);
  position: relative;
}

.required {
  width: 0.3125rem;
  height: 0.3125rem;
  display: inline-block;
  position: absolute;
  top: 0.125rem;
  right: -0.625rem;
  border-radius: 0.625rem;
  background-color: var(--pink-3);
}

.cagetories {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  row-gap: 0.5625rem;
  column-gap: 0.8125rem;
}

.check {
  width: 1.3125rem;
  height: 1.3125rem;
  border-radius: 1.3125rem;
  border: 0.25rem solid var(--gray-3);
  cursor: pointer;
}

.input {
  display: none;
}

.check:has(.input:checked) {
  background-color: var(--blue-4);
}
