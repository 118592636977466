.item {
  padding: 0.625rem 0;
  display: flex;
  flex-direction: column;
  border-bottom: 0.5px solid var(--gray-3);
}

.tag {
  align-self: flex-start;
  position: relative;
  font-size: 0.6875rem;
  color: var(--gray-3-1);
}

.required {
  width: 0.3125rem;
  height: 0.3125rem;
  display: inline-block;
  position: absolute;
  top: 0.125rem;
  right: -0.625rem;
  border-radius: 0.625rem;
  background-color: var(--pink-3);
}

.input {
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--gray-4);
}

.input::placeholder {
  color: var(--gray-3-1);
}
