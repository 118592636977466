.container {
  padding: 0.25rem 0.8125rem;
  margin: 0 1.25rem;
  background: var(--gray-2);
  border-radius: 0.3125rem;
}

.container div:last-child {
  border: none;
}
