.button {
  width: 100%;
  padding: 0.5625rem 0;
  background: var(--gray-2);
  border-radius: 0.3125rem;
  font-size: 0.8125rem;
  color: var(--gray-4);
}

.select {
  background: var(--blue-4);
  color: var(--white);
}
