.link {
  width: 100%;
}

.item {
  width: 100%;
  padding: 0.8125rem 0.9375rem;
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  background: var(--gray-1);
  border-radius: 0.3125rem;
  box-sizing: border-box;
}

/* left */

.left {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.top {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.4375rem;
  font-size: 0.5625rem;
  color: var(--gray-4);
}

.dot {
  width: 0.125rem;
  height: 0.125rem;
  display: inline-block;
  border-radius: 0.625rem;
  background-color: var(--gray-4);
}

.bottom {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  height: 3.25rem;
}

.title {
  margin-bottom: 0.125rem;
  font-weight: 500;
  font-size: 0.75rem;
}

.overview {
  font-size: 0.6875rem;
  color: var(--gray-4);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.boardName {
  font-size: 0.5rem;
  color: var(--gray-3-1);
}

/* right */

.right {
  display: flex;
  align-items: flex-end;
}

.right img {
  width: 4.75rem;
  height: 4.75rem;
  border-radius: 0.25rem;
}
