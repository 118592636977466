.label {
  margin-bottom: 0.375rem;
  display: inline-block;
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--gray-4);
}

.input {
  width: 100%;
  padding: 0.75rem 0 0.6875rem 0.8125rem;
  background: var(--gray-1-1);
  border-radius: 0.3125rem;
  border: none;
  outline: none;
  font-size: 0.75rem;
}

.input::placeholder {
  color: var(--gray-3-1);
}

.input.isInvalid {
  background: var(--pink-1);
  color: var(--pink-3);
}

.errorMessage {
  margin-top: 3px;
  font-size: 0.6875rem;
  color: var(--pink-3);
}
