.card {
  width: 100%;
  height: 7rem;
  padding: 0.835rem 0 1rem 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--blue-0);
  border-radius: 0.375rem;
  box-sizing: border-box;
  color: var(--gray-4);
  overflow: hidden;
}

.dark .card {
  background: radial-gradient(
    79.53% 146.8% at 31.85% 57.65%,
    var(--blue-4) 0%,
    var(--blue-3) 100%
  );
  color: var(--white);
}

.text {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  word-break: keep-all;
}

.title {
  display: -webkit-box; /* Required for the ellipsis effect */
  -webkit-line-clamp: 2; /* Limit text to 4 lines */
  -webkit-box-orient: vertical; /* Required for the ellipsis effect */
  overflow: hidden; /* Hide text overflow */
  white-space: pre-line;
  font-size: 1rem;
  font-weight: 500;
}

.tag {
  font-size: 0.75rem;
  color: var(--gray-4);
}

.dark .tag {
  color: var(--gray-1-1);
}

.image {
  width: 4.5rem;
  height: auto;
}
