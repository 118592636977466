.pageFrame {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
}

.pageTopFrame {
  padding: 0 1.25rem;
}

.pageTitle {
  font-size: 1.25rem;
  font-weight: 700;
  margin-top: 3.2rem;
}

.pageExplanation {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--gray-4);
  margin-top: 0.875rem;
}

.pageBottomFrame {
  padding: 0 1.25rem;
  display: flex;
  flex-direction: column;
}

.bodyFrame {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.resultFrame {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.result {
  margin-top: 0.6rem;
  font-size: 1.563rem;
  font-weight: 700;
  color: var(--blue-4);
}

.dot {
  height: 0.375rem;
  width: 0.375rem;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 0.438rem;
}

.first {
  background-color: var(--blue-2);
}

.second {
  background-color: var(--blue-3);
}

.third {
  background-color: var(--blue-4);
}

.pageMiddleFrame {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginButton {
  margin-bottom: 1.125rem;
}

.findPWButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.875rem;
  color: var(--gray-4);
  font-size: 0.75rem;
}
