.title {
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 0.349rem;
}

.input {
  width: 100%;
  height: 2.563rem;
  border-radius: 0.375rem;
  padding-left: 1rem;
  padding-right: 0.625rem;
  font-size: 0.75rem;
  outline: 0;
}

.input [type='date']::-webkit-calendar-picker-indicator {
  width: 10rem;
  height: 1.188rem;
  background-color: var(--blue-4);
}

.ready {
  border: 0;
  background-color: var(--gray-1-1);
}

.ready::placeholder {
  color: var(--gray-3-1);
}

.right {
  border: 0;
  background-color: var(--blue-0);
  color: var(--blue-4);
}

.wrong {
  border: 0;
  background-color: var(--pink-1);
  color: var(--pink-3);
}

.errMsg {
  font-size: 0.688rem;
  color: var(--pink-3);
}
