.container {
  max-width: var(--default-width);
  width: 100%;
  min-height: 5.25rem;
  padding: 0.9375rem 0.9375rem 1.875rem 0.9375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  background: var(--white);
  border-radius: 0.625rem 0.625rem 0 0;
  box-shadow: 0 -0.25rem 0.4375rem var(--blue-1);
  position: fixed;
  bottom: 0;
}

.input_bar {
  background-color: var(--gray-1-1);
  width: 100%;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  gap: 0.4375rem;
  padding: 0.5625rem 0.75rem;
  border-radius: 0.3125rem;
}

.input_zone {
  width: 100%;
  min-height: 1.25rem;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 0.75rem;
  resize: none;
}

.input_zone::placeholder {
  color: var(--gray-3-1);
}

.profile_icon {
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
}

.enter {
  cursor: pointer;
}
