.nav {
  width: 100%;
  max-width: var(--default-width);
  padding: 0.8rem 2.25rem;
  border-radius: 0.625rem 0.625rem 0 0;
  position: fixed;
  bottom: 0;
  z-index: 1;
  background-color: var(--white);
  filter: drop-shadow(0 -0.25rem 0.4375rem var(--blue-1));
}

.menus {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.menu span {
  font-size: 0.5625rem;
  color: var(--blue-2);
}
