.link {
  width: 100%;
}

.card {
  padding: 0.7rem 0 0 0.9rem;
  width: 100%;
  height: 5.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.12rem;
  border-radius: 0.375rem;
  background: var(--gray-1-1);
  background-repeat: no-repeat;
  background-position: right bottom;
}

.name {
  font-size: 0.875rem;
  font-weight: 500;
}

.name.notLogin {
  color: var(--gray-3-1);
}

.desc {
  width: 5rem;
  font-size: 0.6875rem;
  color: var(--gray-3-1);
  word-break: keep-all;
}
