/* top */

.top {
  display: flex;
  flex-direction: column;
  background-color: var(--blue-4);
  border-radius: 0 0 0.9375rem 0.9375rem;
}

.title {
  margin-bottom: 1.875rem;
  font-weight: 700;
  font-size: 1.25rem;
  text-align: center;
  color: var(--white);
  white-space: pre-line;
}

.calendar {
  display: flex;
  justify-content: center;
}

.attendanceButton {
  margin: 1.625rem var(--default-margin);
  padding: 0.8125rem 0;
  background: var(--blue-0);
  border-radius: 0.3125rem;
  font-weight: 500;
  font-size: 0.875rem;
  text-align: center;
  color: var(--blue-4);
}

.attendanceButton:active {
  filter: brightness(0.9);
}

.attendanceButton:disabled {
  cursor: not-allowed;
  filter: brightness(0.5);
}

/* bottom */

.bottom {
  margin: 1.0625rem 0 3.125rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.item {
  margin: 0 var(--default-margin);
  padding: 0.875rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--blue-0);
  border-radius: 0.3125rem;
}

.itemLeft {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.label {
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--blue-4);
}

.description {
  font-size: 0.8125rem;
  color: var(--gray-4);
}
