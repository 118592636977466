.container {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 0.375rem;
}

.title {
  color: var(--gray-4);
  font-size: 0.875rem;
  font-weight: 500;
}

.inputWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2.5rem;
  border-radius: 0.3125rem;
  background: var(--gray-1-1);
  padding: 0.6875rem 0.8125rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.inputText {
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  color: var(--black);
  font-size: 0.75rem;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--gray-3-1);
  }
}

.errorMessage {
  position: absolute;
  color: var(--pink-3);
  font-size: 0.6875rem;
  top: 4.4375rem;
}

.errorInputWrapper {
  background: var(--pink-1);
}

.errorInputText {
  color: var(--pink-3);
}

.rightInputWrapper {
  background: var(--blue-0);
}

.rightInputText {
  color: var(--blue-4);
}
