.layout {
  margin-bottom: 2.5625rem;
  width: 100%;
  aspect-ratio: 3;
  background: linear-gradient(
    45deg,
    var(--gray-2) 25%,
    var(--gray-3) 50%,
    var(--gray-2) 75%
  );
  background-size: 200% 100%;
}
