.tag {
  flex-shrink: 0;
  padding: 0.12rem 0.56rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--blue-0);
  border-radius: 0.3125rem;
}

/* top */
.top {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.nickname {
  color: var(--blue-3);
  font-size: 0.6875rem;
}

.title {
  color: var(--gray-3-1);
  font-size: 0.5625rem;
}

/* bottom */

.studentInfo {
  font-size: 0.5rem;
  color: var(--gray-4);
}
