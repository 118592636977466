.pageFrame {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  padding: 0 1.25rem;
}

.pageFrame form {
  width: 100%;
  height: 100%;
}
.back {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
}
.back:hover {
  cursor: pointer;
}

.findIdFrame {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.arrowLeft {
  margin-top: 0.875rem;
  margin-bottom: 1.703rem;
}

.pageTitle {
  font-size: 1.25rem;
  font-weight: 700;
  margin-top: 0.875rem;
  margin-bottom: 3.5rem;
}

.inputFrame {
  margin-bottom: 1.5rem;
}

.buttonFrame {
  margin-top: 1.5rem;
  margin-bottom: 2.125rem;
}

.alert {
  font-size: 12px;
  color: grey;
  padding: 0 2rem;
  margin-top: 3rem;
  line-height: 1.7;
  word-break: keep-all;
}
.alert ul {
  list-style-type: circle;
}
.alert a {
  color: var(--blue-3);
}
