.pageFrame {
  height: 100%;
  padding: 0 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.icon {
  margin-top: 3.313rem;
  margin-bottom: 1.188rem;
}

.title {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.img {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit {
  margin-top: 1.875rem;
  margin-bottom: 2.125rem;
}
