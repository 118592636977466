.effect * {
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  from {
    background-position: 100%;
  }

  to {
    background-position: -100%;
  }
}
