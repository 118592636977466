.list {
  margin: 0 var(--default-margin);
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
}

.item {
  width: 100%;
  height: 115.5px;
  border-radius: 0.3125rem;
  background: linear-gradient(
    45deg,
    var(--gray-2) 25%,
    var(--gray-3) 50%,
    var(--gray-2) 75%
  );
  background-size: 200% 100%;
}
