.layout {
  max-width: calc(100% - 2 * var(--default-margin));
  padding: 0.3125rem 0.75rem;
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;
  background: var(--blue-2);
  border-radius: 0.3125rem;
  font-size: 0.75rem;
  color: var(--blue-4);
  word-break: keep-all;
}

.name {
  flex: 1;
}
