.activityPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.contentWrapper {
  flex: 1;
  padding: 2.813rem 1.25rem 3.375rem 1.25rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  padding: 0.875rem 0;
}

.title {
  font-size: 1.25rem;
  font-weight: 700;
}
