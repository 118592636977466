.main {
  margin-bottom: 3.125rem;
  padding-top: 3.875rem;
}

.file {
  margin: 0 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.left {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
}

.left .required {
  width: 0.3125rem;
  height: 0.3125rem;
  display: inline-block;
  position: absolute;
  top: 0.125rem;
  right: -0.625rem;
  border-radius: 0.625rem;
  background-color: var(--pink-3);
}

.tag {
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--gray-4);
}

.right {
  flex: 1;
}

label[for='file'] {
  padding: 0.5rem 1rem;
  display: block;
  background: var(--gray-2);
  border-radius: 0.3125rem;
  font-size: 0.8125rem;
  color: var(--gray-3-1);
  cursor: pointer;
}

input[type='file'] {
  display: none;
}
