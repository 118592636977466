.loading {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerBox {
  padding: 1.875rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.625rem;
  font-size: 0.8125rem;
}

.icon {
  animation: rotateAndPause 1s linear infinite;
}

.iconStatic {
  animation: none;
}

@keyframes rotateAndPause {
  0% {
    transform: rotate(180deg);
  }

  15% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
