.item {
  padding: 0.75rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid var(--gray-3);
}

.item:last-child {
  border: none;
}

.tag {
  margin-right: 0.875rem;
  flex-shrink: 0;
  font-size: 0.75rem;
  color: var(--gray-3-1);
}

.value {
  font-size: 0.875rem;
  color: var(--gray-4);
}

.flexStart {
  align-self: flex-start;
}
