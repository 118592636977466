.accordion {
  border-bottom: 0.5px solid var(--gray-3);
}

.accordion:last-child {
  border: none;
}

.panelHeader {
  padding: 0.875rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.panelHeader span {
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--gray-4);
}

.opend .panelHeader {
  border-bottom: 0.5px solid var(--gray-3);
}

.toggleIcon {
  transition: 300ms transform ease;
}

.opend .toggleIcon {
  transform: rotate(180deg);
}

.panelBody {
  max-height: 0;
  color: var(--gray-4);
  font-size: 0.875rem;
  letter-spacing: -0.03125rem;
  overflow: hidden;
  transition: 300ms all ease;
}

.opend .panelBody {
  padding: 1rem 0 0.625rem 0;
  max-height: 900px;
}
