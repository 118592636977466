.toast {
  padding: 0.8125rem 2rem;
  background: rgba(var(--gray-4-rgb), 0.8);
  border-radius: 0.375rem;
  transition: 500ms opacity ease-in-out;
}

.message {
  text-align: center;
  word-break: keep-all;
  font-weight: 500;
  font-size: 0.75rem;
  white-space: pre-wrap;
  color: var(--white);
}
