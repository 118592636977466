.dropdown {
  padding: 0.6rem 0;
  background: var(--gray-2);
  border-radius: 0.3125rem;
  border: none;
  font-size: 0.8125rem;
  color: var(--gray-4);
}

.select {
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select:hover {
  cursor: pointer;
}

.unselect {
  color: var(--gray-3-1);
}

.placeholder {
  color: var(--gray-3-1);
}

.list {
  max-height: 0;
  overflow: hidden;
  transition: 200ms all ease-in-out;
  text-align: left;
}

.open .list {
  max-height: 16rem;
  margin-top: 0.375rem;
  overflow: auto;
}

.option {
  padding: 0.5rem 0.625rem;
  margin: 0 0.375rem;
  border-radius: 0.25rem;
}

.option:hover {
  background-color: var(--gray-3);
  cursor: pointer;
}
