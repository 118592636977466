.main {
  height: 94%;
  display: flex;
  flex-direction: column;
}

.indicator {
  margin: 0.5625rem var(--default-margin) 28px var(--default-margin);
  display: flex;
  gap: 0.6875rem;
}

.check {
  margin: 0.8125rem var(--default-margin) 1.1875rem
    calc(var(--default-margin) - 0.1875rem);
}

.dot {
  width: 0.5625rem;
  height: 0.5625rem;
  border-radius: 100%;
  background-color: var(--gray-2);
}

.dot.select {
  background: var(--blue-4);
}

.title {
  margin: 0 var(--default-margin) 1rem var(--default-margin);
  font-weight: 700;
  font-size: 1.25rem;
  white-space: pre-line;
}

.description {
  margin: 0 var(--default-margin) 1.6875rem var(--default-margin);
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--gray-4);
  white-space: pre-line;
  word-break: keep-all;
}
