.content {
  flex: 1;
  margin: 0 var(--default-margin);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.terms {
  flex: 1;
  margin-bottom: 0.875rem;
  padding: 0.875rem;
  background: var(--gray-1-1);
  border-radius: 0.3125rem;
}

.text {
  word-break: keep-all;
  text-wrap: wrap;
  font-size: 0.875rem;
  color: var(--gray-4);
}

.item {
  margin-left: 0.625rem;
}
