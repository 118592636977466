.container {
  width: 100%;
  height: 100vh;
  background-color: var(--blue-0);
}

.top {
  width: 100%;
  padding-top: 3.25rem;
}

.center {
  padding: 0 1.25rem;
  background-color: var(--blue-0);
}

.categorySelect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 0 0.9375rem 0;
  border-bottom: 0.5px solid var(--gray-3-1);
  stroke-width: 0.5px;
  cursor: pointer;
}

.categorySelectContainer {
  display: flex;
  align-items: center;
  gap: 0.875rem;
}

.categorySelectText {
  font-size: 1rem;
  font-weight: 500;
}

.profileBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.375rem 0 1.125rem 0;
  color: var(--gray-3-1);
  font-size: 0.75rem;
}

.profileBoxLeft {
  display: flex;
  align-items: center;
  gap: 0.4375rem;
}

.profileBoxRight {
  display: flex;
  align-items: center;
  gap: 0.125rem;
  color: var(--black);
  cursor: pointer;
}

.profileBoxRightInvisible {
  display: none;
  cursor: pointer;
}

.dot {
  width: 0.1875rem;
  height: 0.1875rem;
  border-radius: 50%;
  background-color: var(--gray-3-1);
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 2.25rem;
  padding-bottom: 6.875rem;
}

.title {
  color: var(--gray-4);
  font-size: 1.25rem;
  font-weight: 700;
  outline: none;
  border: none;
  background-color: transparent;
  display: block;
  width: 100%;
  resize: none;
}

.text {
  color: var(--gray-4);
  font-size: 0.875rem;
  outline: none;
  border: none;
  background-color: transparent;
  display: block;
  width: 100%;
  min-height: 6.25rem;
  resize: none;
}

.bottom {
  height: 100%;
}
