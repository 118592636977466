.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.75rem 0 7rem;
  background: linear-gradient(180deg, #f9f9f9 0%, var(--white) 100%);
  padding-top: 1.75rem;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  margin-top: 0.875rem;
  font-size: 10px;
  text-align: center;
  color: #6a6a6a;
}

.email {
  font-size: 11px;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  display: flex;
  padding: 0 6px;
}
