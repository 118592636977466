.serverError {
  flex: 1;
  margin: 0 var(--default-margin);
  padding: 2rem;
  aspect-ratio: 3.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 0.3125rem;
  font-size: 1rem;
  color: var(--gray-4);
}

.errorMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.errorMessage > span:nth-child(1) {
  font-size: 1.1rem;
  font-weight: 500;
}

.errorMessage > span:nth-child(2) {
  font-size: 0.75rem;
}
