/* 모달 공통 스타일 */
.dim {
  width: 100vw;
  max-width: var(--default-width);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(228, 228, 228, 0.6);
  backdrop-filter: blur(0.625rem);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  width: 18rem;
  padding: 1.25rem 0.625rem 0.625rem;
  border-radius: 0.625rem;
  background: var(--white);
}

.buttonGroup {
  display: flex;
  width: 100%;
}

.title {
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  color: var(--black);
  white-space: pre-line;
}

.deleteCenter {
  width: 80%;
  text-align: center;
  color: var(--gray-4);
  font-size: 0.75rem;
  white-space: pre-line;
}

.primaryButton {
  width: 100%;
  padding: 0.625rem 0.75rem;
  background-color: transparent;
  font-weight: 500;
  color: var(--pink-3);
}

.secondaryButton {
  width: 100%;
  padding: 0.625rem 0.75rem;
  background-color: transparent;
  font-weight: 500;
  color: var(--gray-3-1);
}
