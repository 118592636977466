.container {
  margin-top: 0.5rem;
  width: calc(100% - 2.5rem);
  padding: 0.8125rem 0.375rem;
  background-color: var(--gray-2);
  border-radius: 0.3125rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.3125rem;
  position: absolute;
  z-index: 1;
}

.invisible {
  display: none;
}

.listBar {
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  border-radius: 0.3125rem;
  background-color: transparent;
  padding: 0 0.8125rem;
  color: var(--gray-3-1);
  cursor: pointer;
}

.SelectedlistBar {
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  border-radius: 0.3125rem;
  background-color: var(--blue-1);
  padding: 0 0.8125rem;
  color: var(--blue-4);
  cursor: pointer;
}

.item {
  font-size: 0.8125rem;
}
