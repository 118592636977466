/* 상단 배경 */
.myPageUpper {
  width: 100%;
  height: 197px;
  opacity: 0.8;
  z-index: 1;
}

.logoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 434px;
  opacity: 0.8;
  background-image: url('/src/assets/images/symbol_White.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.editIcon {
  position: absolute;
  top: 6px;
  right: 6px;
  padding: 20px;
  width: 20px;
  height: 20px;
}
