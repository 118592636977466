.slide {
  width: 100%;
  height: auto;
  flex-shrink: 0;
}

.slide img {
  width: 100%;
  aspect-ratio: 3.23 / 1;
  object-fit: cover;
  object-position: center;
}
