.top {
  width: 100%;
  padding-bottom: 1.4375rem;
  padding-top: 2.813rem;
  display: flex;
  flex-direction: column;
  background-color: var(--blue-0);
  border-radius: 0 0 0.9375rem 0.9375rem;
}

.displayBox {
  margin: 0.75rem var(--default-margin) 0.25rem var(--default-margin);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
  color: var(--gray-3-1);
}

.displayBoxLeft {
  display: flex;
  align-items: center;
}

.cloudIcon {
  margin-right: 0.4375rem;
}

.checkCircleIcon {
  margin-left: 0.25rem;
}

.dot {
  width: 0.1875rem;
  height: 0.1875rem;
  margin: 0 0.5rem;
  background: var(--gray-3-1);
  border-radius: 100%;
}

.more {
  padding: 0 0.25rem;
  cursor: pointer;
}

.title {
  margin: 0 var(--default-margin);
  font-weight: 700;
  font-size: 1rem;
}

.info {
  padding: 0.125rem 0.8125rem;
  margin: 1.4375rem var(--default-margin);
  background: var(--white);
  border-radius: 0.3125rem;
}

.content {
  margin: 0 var(--default-margin) 1.4375rem var(--default-margin);
  font-size: 0.875rem;
  word-break: keep-all;
}

.fileDownload {
  margin: 0 var(--default-margin) 0 auto;
}

.actions {
  margin: 0.9375rem var(--default-margin) 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.6875rem;
  color: var(--gray-3-1);

  div:nth-of-type(2):hover {
    background-color: rgba(var(--white-rgb), 0.8);
    cursor: pointer;
  }
}

.action {
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.375rem;
  border-radius: 0.3125rem;
}

.comment {
  margin-top: 1px;
}
