.pageFrame {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
}

.backAppBar {
  display: block;
}

.pageTopFrame {
}

.pageMiddleFrame {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.explanation {
  height: fit-content;
  padding: 0 1.25rem;
}

.pageTitle {
  font-size: 1.25rem;
  font-weight: 700;
  margin-top: 3.2rem;
}

.pageExplanation {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--gray-4);
  margin-top: 0.875rem;
}

.pageBottomFrame {
  padding: 0 1.25rem;
  display: flex;
  flex-direction: column;
}

.goBackButton {
  display: block;
  margin-bottom: 0.5rem;
}

.loginButton {
  margin-bottom: 2.063rem;
}
