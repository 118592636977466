.filter {
  position: relative;
  z-index: 1;
}

.display {
  padding: 0.1875rem 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: var(--blue-0);
  border-radius: 1.25rem;
  cursor: pointer;
}

.displayOption {
  font-size: 0.6875rem;
  color: var(--blue-4);
}

.list {
  width: 10.6rem;
  max-height: 15rem;
  padding: 0.375rem;
  display: none;
  background: var(--blue-0);
  box-shadow: 0.25rem 0.25rem 1.25rem rgba(0, 0, 0, 0.05);
  border-radius: 0.3125rem;
  position: absolute;
  top: calc(100% + 0.625rem);
  overflow-y: auto;
}

.open .list {
  display: block;
}

.option {
  padding: 0.5rem 0 0.5rem 0.75rem;
  border-radius: 0.3125rem;
  font-size: 0.8125rem;
  color: var(--gray-3-1);
  cursor: pointer;
}

.option:hover {
  background: var(--blue-2);
  color: var(--blue-4);
}
