.noContentWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.noContentMessage {
  color: var(--gray-4);
  font-size: 1rem;
  font-weight: 500;
}

.imageWrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: auto;
  height: auto;
  min-height: 36.25rem;
}

.image {
  position: absolute;
  right: 0;
  bottom: 0;
}

.posts {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.to {
  width: 100%;
}
