.container {
  width: 100%;
  height: 100%;
}

.paddingContainer {
  padding: 0 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  font-size: 0.5rem;
}

.header {
  height: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.searchbarBox {
  width: 100%;
  /* 양옆 여백 */
  padding: 0.625rem 0 0 0;
}

.searchbar {
  border-radius: 0.3125rem;
  font-size: 0.75rem;
  color: var(--gray-1-1);
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--gray-1);
  padding: 0 0.8125rem;

  input {
    width: 100%;
    padding: 0 0.625rem 0.125rem 0.625rem;
    border-radius: 0.625rem;
    border: transparent;
    background: var(--gray-1);
  }

  input:focus {
    outline: none;
  }
}

.boardBox {
  padding: 1.625rem 0;
  width: 100%;
}

.boardTitle {
  box-sizing: border-box;
  padding: 0 0 0.5rem 0;
  font-size: 1rem;
  font-weight: 700;
}

.boardListBox {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-row: auto auto;
  grid-column-gap: 0.625rem;
  grid-row-gap: 0.625rem;
}

.moreBoardBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.875rem 0;
  min-height: 18.75rem;
  background: linear-gradient(180deg, #f9f9f9 0%, var(--white) 100%);
  font-size: 0.75rem;
}

.iconLogo {
  margin-bottom: 0.625rem;
}
