.viewPointListPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.contentWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem 3.375rem 1.25rem;
  width: 100%;
  padding-top: 2.813rem;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  padding: 0.875rem 0;
}

.title {
  font-size: 1.25rem;
  font-weight: 700;
}

.totalPointWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.totalPoint {
  color: var(--blue-4);
  font-size: 0.875rem;
  font-weight: 500;
}
