.changePasswordPage {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.topContainer {
  display: flex;
  justify-content: space-between;
}

.submitBtn {
  background-color: transparent;
  padding: 0.875rem 1.25rem;
  font-size: 1rem;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  padding: 0.875rem 1.25rem;
  gap: 1.75rem;
}
